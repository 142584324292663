import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { getCourseInfo, getPaymentsHistory } from "../../utils/api";
import { Box, CircularProgress, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { STRIPE_PRODUCT_DISPLAY_NAMES } from '../../config/const';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const PurchaseHistory: React.FC = () => {
  const { authUser } = useAuth();
  const [ paymentsHistory, setPaymentsHistory] = useState([]);
  const [ page, setPage ] = useState(0);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const fullyLoaded = useRef<boolean>(false);
  const bottom = useRef(null);

  useEffect(() => {
    try {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadNextPage();
        }
      });
      observer.observe(bottom.current);

      return () => {
        observer.disconnect();
      };
    }
    catch (err) {
      // To-do: Error handling when observer fails
    }
  }, [paymentsHistory]) //eslint-disable-line react-hooks/exhaustive-deps

  const loadNextPage = () => {
    if (!isLoading && authUser && !fullyLoaded.current) {
      setIsLoading(true);
      getPaymentsHistory(authUser.documentId, page+1, 25)
        .then(res => res.json())
        .then(async (data) => {
          setPage(data.page)
          await addCourseDataToPaymentHistory(data?.data || [])

          setPaymentsHistory(paymentsHistory.concat(data.data))

          if (!data.data.length) {
            fullyLoaded.current = true;
          }
          setIsLoading(false);
        })
        .catch(err => {
          // To-do: error handling for failed payment history loading
          console.log(err)
          fullyLoaded.current = true;
          setIsLoading(false);
        })
    }
  }

  const addCourseDataToPaymentHistory = async (history: [any]) => {
    return new Promise(resolve => {
      let finished = 0;

      let completePromise = () => {
        finished++;
        if(finished === history.length) {
          resolve(history)
        }
      };

      history.forEach((entry: any) => {
        if (entry.item_id) {
          getCourseInfo(entry.item_id)
            .then(res => {
              if (res.ok) {
                return res.json();
              }
            })
            .then(courseData => {
              entry["course_info"] = courseData;
            })
            .catch(err => {
              console.log('Failed to fetch course')
            })
            .finally(completePromise)
        } else {
          finished++;
        }
      })
      if (finished === history.length) {
        resolve(history)
      }
    });
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      paddingTop: '10px',
      paddingBottom: '10px',
      borderTop: '1px solid rgba(0,0,0,0.12)',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      fontSize: 16,
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
    },
  }));

  function getFormattedDate(date: Date) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }

  return (
    <Box
      sx={{
        border: '1px solid rgba(0,0,0,0.12)',
        borderRadius: '8px',
        padding: '16px 24px',
        width: '100%',
        maxWidth: '1000px',
        mt: 2
      }}
    >
      <Typography component='h6' variant='h6' fontWeight={600}  sx={{
        fontSize: '18px', // Change the font family
        color: 'black',
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        height: '1.5em',
        marginBottom: '20px',
      }}>
        Billing History
      </Typography>
      <TableContainer>
        <Table size="small" aria-label="a dense table" className={"payments-table"}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="left">Item</StyledTableCell>
              <StyledTableCell align="left">Credits</StyledTableCell>
              <StyledTableCell align="left">Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentsHistory.map((row) => {
              let date = new Date(row.createdAt);

              return (
                <TableRow
                  key={row.docId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {getFormattedDate(date)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.course_info ?
                      <Link
                        id={`href-course-learn-${row?.course_info?.course_id}`}
                        to={`/learn/${row?.course_info?.course_id}`}
                        state={{courseItem: row?.course_info}}
                        style={{
                          color: '#2b6a9b',
                          textDecoration: 'underline',
                        }}
                      >
                        {row?.course_info?.name}
                      </Link> :
                      row.product_name ?
                        (row.product_redirect_link ?
                          <Link
                            id={`href-premium-product`}
                            to={row.product_redirect_link || null}
                            style={{
                              color: '#2b6a9b',
                              textDecoration: 'underline',
                            }}
                          >
                            {row.product_name}
                          </Link> :
                          row.product_name
                        ):
                        STRIPE_PRODUCT_DISPLAY_NAMES[row.item] || ""
                    }
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.credits}</StyledTableCell>
                  <StyledTableCell align="left">${row.price}</StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        { isLoading ?
            <Box
              display='flex'
              width='100%'
              padding='1.5rem'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress size={30} />
            </Box> :
            paymentsHistory.length === 0 ?
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 1,
                }}
              >
                <Typography
                  component='h6'
                  variant='h6'
                  fontWeight={400}
                  sx={{
                    fontSize: '14px',
                    color: 'gray',
                  }}
                >
                  No purchase history found
                </Typography>
              </Box> :
              <></>
        }
        <div className="custom-bottom" ref={bottom}/>
      </TableContainer>
    </Box>
  )
}

export default PurchaseHistory
