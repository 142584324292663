import { Avatar, Box, Typography } from "@mui/material";
import DefaultUserImage from '../../assets/images/default-avatar.png';
import { getFormattedDate } from "../../utils/helper";
import { useMemo } from "react";

interface CreatorSubscriptionDataProps {
  creatorData?: any;
}

const CreatorSubscriptionData: React.FC<CreatorSubscriptionDataProps> = ({creatorData}) => {
  const CreatorAvatar = useMemo(() => {
    return (
      <Avatar
        src={creatorData?.creator_pic || DefaultUserImage}
        sx={{
          mr: 1
        }}
        slotProps={{
          img: {
            loading: 'lazy',
          }
        }}
      />
    )
  }, [creatorData])

  return (
    creatorData ?
    <Box display='flex' justifyContent={'flex-start'}>
      {CreatorAvatar}
      <Box display='flex' flexDirection='column'>
        <Typography variant='body1' color='black' fontWeight={600}>
          {`${creatorData.creator_name}`}
        </Typography>
        <Typography variant='body1' color='rgba(0,0,0,0.6)' fontSize='0.9rem'>
          {`$${Math.round(creatorData.amount)/100}/${creatorData.interval}`}
        </Typography>
        <Typography variant='body1' color='rgba(0,0,0,0.6)' fontSize='0.9rem'>
          {`Renews: ${getFormattedDate(new Date(creatorData.current_period_end*1000))}`}
        </Typography>
      </Box>
    </Box> :
    <></>
  )
}

export default CreatorSubscriptionData;
