import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';

import {
  CONVERSATIONAL_SECTION_DATA,
  TRAIN_SECTION_LEVELS_DATA,
  TRAIN_SECTION_STATUS,
  USER_TYPE,
} from '../../config/const';
import { useAuth } from '../../hooks/useAuth';
import LevelItems from './levelItems';
import { fetchCreatorLevelInfo } from '../../utils/api';
import ConversationItem from './conversationItem';
// import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const convOnboardingEnabled = JSON.parse(
  process.env.REACT_APP_ENABLE_CONVERSATIONAL_ONBOARDING
);

export default function TrainSection() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [levelData, setLevelData] = React.useState([]);
  const [conversationData, setConversationData] = React.useState([]);

  const { authUser } = useAuth();
  const navigate = useNavigate();
  // const eventTracker = useAnalyticsEventTracker();

  const {
    data,
    isLoading: levelDataLoading,
    isFetching,
  } = useQuery({
    queryKey: ['creator-level-info', authUser?.documentId],
    queryFn: fetchCreatorLevelInfo,
  });
  React.useEffect(() => {
    if (!isFetching && data){
    }
  }, [isFetching]) //eslint-disable-line react-hooks/exhaustive-deps


  React.useEffect(() => {
    if (
      authUser &&
      (authUser?.userType !== USER_TYPE?.CREATOR || !authUser?.index)
    ) {
      toast.error('You are not authorized to access this location');
      navigate(`/creators`);
      return;
    }
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (data) {
      setUserLevelInfoData(data);
      setUserConversationData(data);
    }
  }, [data]);

  const setUserLevelInfoData = async (userLevelData) => {
    try {
      setIsLoading(true);
      const userData: any = Promise.all(
        TRAIN_SECTION_LEVELS_DATA.map(async (levelItem, levelIndex) => {
          const { levelId } = levelItem;
          levelItem = {
            ...levelItem,
            status:
              userLevelData?.[`${levelId}_status`] ||
              TRAIN_SECTION_STATUS?.GENERATING, // set level status if not exists set generating status
            progress: userLevelData?.[`${levelId}_progress`] || 0,
            isCurrent: false,
            isCompleted: userLevelData?.[`${levelId}_progress`] === 100,
            isGenerating:
              userLevelData?.[`${levelId}_progress`] === undefined ||
              userLevelData?.[`${levelId}_status`] === undefined ||
              userLevelData?.[`${levelId}_status`] ===
                TRAIN_SECTION_STATUS?.GENERATING,
          };

          //check current level
          if (
            (levelIndex === 0 &&
              userLevelData?.[`${levelId}_progress`] < 100) ||
            (userLevelData?.[`${levelId}_status`] !==
              TRAIN_SECTION_STATUS?.GENERATING &&
              userLevelData?.[`level_${levelIndex}_progress`] >= 100 &&
              userLevelData?.[`${levelId}_progress`] < 100)
          ) {
            levelItem = {
              ...levelItem,
              isCurrent: true,
            };
          }
          return levelItem;
        })
      );
      setLevelData(await userData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error('There is some error with the level');
    }
  };

  const setUserConversationData = async (userLevelData) => {
    try {
      setIsLoading(true);
      const userData: any = Promise.all(
        CONVERSATIONAL_SECTION_DATA.map(
          async (conversationItem, conversationIndex) => {
            const { topic } = conversationItem;
            conversationItem = {
              ...conversationItem,
              status: 'Not Started',
              // userLevelData?.[`${topic}_status`] ||
              // TRAIN_SECTION_STATUS?.GENERATING, // set level status if not exists set generating status
              progress: userLevelData?.[`${topic}_progress`] || 0,
              isCurrent: false,
              isCompleted: userLevelData?.[`${topic}_progress`] === 100,
              isGenerating: false,
              // userLevelData?.[`${topic}_progress`] === undefined ||
              // userLevelData?.[`${topic}_status`] === undefined ||
              // userLevelData?.[`${topic}_status`] ===
              // TRAIN_SECTION_STATUS?.GENERATING,
            };

            //check current level
            if (conversationItem.progress < 100) {
              conversationItem = {
                ...conversationItem,
                isCurrent: true,
              };
            }
            return conversationItem;
          }
        )
      );
      setConversationData(await userData);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error('There is some error with the conversation');
    }
  };

  const handleLevelClick = async (levelId: string) => {
    setIsLoading(true);
    try {
      navigate(`/train/${levelId}`);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error('There is some error with the level');
    }
  };

  const handleConversationClick = async (topic: string) => {
    setIsLoading(true);
    try {
      navigate(`/${authUser?.userName}/conversation/${topic}`);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error('There is some error with the conversation');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      {isLoading || levelDataLoading || isFetching ? (
        <Box
          mt={2}
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          <Box display='flex' justifyContent='center' py={2}>
            <Typography
              component='h6'
              variant='h3'
              fontSize='1.4rem'
              fontWeight={500}
            >
              Discover Your AI Persona
            </Typography>
          </Box>
          <Box
            display='flex'
            flex={1}
            alignItems='center'
            flexDirection='column'
            px={4}
            pt={1}
            pb={2}
            rowGap={3}
            sx={{ width: 1 }}
          >
            <>
              {convOnboardingEnabled && conversationData?.length >= 1 && (
                <>
                  <Box sx={{ border: 'none', p: 0 }}>
                    <Typography
                      variant={'h6'}
                      fontWeight={600}
                      color='black'
                      title='1. Topics Of Interest'
                    >
                      1. Topics Of Interest
                    </Typography>
                  </Box>
                  {conversationData?.map((conversationItem, index) => (
                    <ConversationItem
                      conversationData={conversationItem}
                      key={index}
                      handleConversationClick={handleConversationClick}
                    />
                  ))}
                </>
              )}
              {convOnboardingEnabled && (
                <Box sx={{ border: 'none', p: 0, pt: 5 }}>
                  <Typography
                    variant={'h6'}
                    fontWeight={600}
                    color='black'
                    title='2. Personality Profiling'
                  >
                    2. Personality Profiling
                  </Typography>
                </Box>
              )}
              {levelData?.length >= 1 &&
                levelData?.map((levelItem, index) => (
                  <LevelItems
                    levelItem={levelItem}
                    key={index}
                    handleLevelClick={handleLevelClick}
                  />
                ))}
            </>
          </Box>
        </>
      )}
    </Box>
  );
}
