import React, { useEffect, useState } from "react";
import { SubscriptionType } from "./SubscriptionData"
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import CloseIcon from "../../svg/CloseIcon";
import CreatorSubscriptionData from "./CreatorSubscriptionData";
import GenericModal from "../../component/GenericModal";
import timeoutImage from '../../assets/images/timeout.png';

interface CancelSubscriptionModalProps {
  subscription: SubscriptionType;
  setSubscription: React.Dispatch<React.SetStateAction<SubscriptionType>>;
}

const CancelSubscriptionModal: React.FC<CancelSubscriptionModalProps> =
  ({subscription, setSubscription}) => {
  const [ showCancelModal, setShowCancelModal ] = useState<boolean>(false);
  const [ showGenericModal, setShowGenericModal ] = useState<boolean>(false);
  const [ genericModalData, setGenericModalData ] = useState(null);
  const [ stepNumber, setStepNumber ] = useState<number>(1);

  useEffect(() => {
    if (subscription) {
      setShowCancelModal(true)
    }
  }, [subscription])

  let handleCloseCancelSubModal = () => {
    setShowCancelModal(false);
    setSubscription(null);
    setStepNumber(1);
  }

  let handleCancelSubscription = (event: any) => {
    event.preventDefault();
    handleCloseCancelSubModal();

    // To-do: Replace everything below here with actual cancellation
    let cancellationData = {};
    cancellationData['title'] = "We're sorry to see you go";
    cancellationData['message'] = 'To cancel your subscription, email Soopra at <a href="mailto:support@soopra.ai"><u>support@soopra.ai</u></a>'
    cancellationData['imgSrc'] = timeoutImage;
    cancellationData['buttons'] = [
      {
        'text': "Close",
        'action': () => setShowGenericModal(false),
        'variant': 'contained'
      }
    ];
    setGenericModalData(cancellationData);
    setShowGenericModal(true);
  }

  return (
    <>
    <Dialog
        open={showCancelModal}
        onClose={handleCloseCancelSubModal}
        fullWidth
        maxWidth='xs'
      >
        <DialogContent>
          <Box display='flex' flexDirection='column'
            sx={{
              width: '100%',
            }}
          >
            <IconButton
              id='button-close-cancel-subscription-modal'
              onClick={handleCloseCancelSubModal}
              color='primary'
              sx={{
                position: 'absolute',
                alignSelf: 'flex-end',
                minWidth: '36px',
                minHeight: '36px',
                height: '36px',
                width: '36px',
                top: '16px',
                right: '16px',
              }}
            >
              <CloseIcon height='30px' width='30px' />
            </IconButton>
            <Typography variant='body1' color='black' fontWeight={600}
              sx={{
                mb: 2,
              }}
            >
              {
                stepNumber === 1 ?
                  'Subscription Details' :
                  'Cancel Subscription?'
              }
            </Typography>
            <CreatorSubscriptionData creatorData={subscription} />
            {
              stepNumber === 1 ?
                <Button variant='outlined' fullWidth size='small'
                  sx={{
                    mt: 2,
                  }}
                  onClick={() => {
                    setStepNumber(2)
                  }}
                >
                  Cancel Subscription
                </Button> :
                <Box sx={{mt: 2}}>
                  <Button variant='contained' fullWidth size='small'
                    sx={{
                      backgroundColor: '#ffa726 !important'
                    }}
                    onClick={handleCancelSubscription}
                  >
                    Yes, Cancel Subscription
                  </Button>
                  <Button variant='outlined' fullWidth size='small'
                    sx={{
                      mt: 2,
                    }}
                    onClick={handleCloseCancelSubModal}
                  >
                    No, Continue Subscription
                  </Button>
                </Box>
            }
          </Box>
        </DialogContent>
      </Dialog>

      <GenericModal showGenericModal={showGenericModal} setShowGenericModal={setShowGenericModal} data={genericModalData}/>
      </>
  )
}

export default CancelSubscriptionModal
