import { Box, Container } from '@mui/system';
import { Typography } from '@mui/material';
import TermsAndConditions,
  { SubscriptionConditions, PrivacyPolicy, ContactInfo } from './terms';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <Box>
      <Container>
        <Typography variant='h2' color='black' sx={{pt: 3, pb: 3,}}>
          Terms and Conditions
        </Typography>

        <Typography variant='body1' color='black' fontWeight={600} sx={{ whiteSpace: 'pre-line'}}>
          {TermsAndConditions.title}
        </Typography>

        <Typography variant='body1' color='black' sx={{ whiteSpace: 'pre-line'}}>
          {TermsAndConditions.description}
        </Typography>

        <ol className='terms-list' style={{paddingLeft: '32px'}}>
          {TermsAndConditions.listItems.map((item, index) => (
            <TermListItem key={`terms-${index}`} item={item} idx={`terms-${index}`} />
          ))}
        </ol>
      </Container>

      <Container sx={{ pt: 4 }} >
        <Typography variant='body1' color='black' fontWeight={600}
          sx={{
            whiteSpace: 'pre-line',
            pb: 1,
          }}
        >
          {SubscriptionConditions.title}
        </Typography>

        <Typography variant='body1' color='black' sx={{ whiteSpace: 'pre-line'}}>
          {SubscriptionConditions.description}
        </Typography>

        <ol className='terms-list' style={{paddingLeft: '32px'}}>
          {SubscriptionConditions.listItems.map((item, index) => (
            <TermListItem key={`subscription-${index}`} item={item} idx={`subscription-${index}`} />
          ))}
        </ol>
      </Container>

      <Container sx={{ pt: 4 }} >
        <Typography variant='body1' color='black' fontWeight={600}
          sx={{
            whiteSpace: 'pre-line',
            pb: 1,
          }}
        >
          {PrivacyPolicy.title}
        </Typography>

        <Typography variant='body1' color='black' sx={{ whiteSpace: 'pre-line'}}>
          {PrivacyPolicy.description}
        </Typography>

        <ol className='terms-list' style={{paddingLeft: '32px'}}>
          {PrivacyPolicy.listItems.map((item, index) => (
            <TermListItem key={`privacy-${index}`} item={item} idx={`privacy-${index}`} />
          ))}
        </ol>

        <Typography variant='body1' color='black' sx={{pt: 3}}>
          {PrivacyPolicy.footer}
        </Typography>
      </Container>

      <Container className={'contact-info'} sx={{ pt: 4, pb: 8, }} >
        <Typography variant='body1' color='black' fontWeight={700} >
          {ContactInfo.title}
        </Typography>
        {
          ContactInfo.description.map((part, index) => (
            typeof part === 'string' ?
              <Typography key={`part-${index}`} variant='body1' color='black' fontWeight={400} component={'span'} >
                {part}
              </Typography> :
              <Link
                to={part.link}
                key={`part-${index}`}
              >
                {part.text}
              </Link>
          ))
        }
      </Container>
    </Box>
  );
};

const TermListItem = (props: any) => {
  let item = props.item;
  let idx = props.idx;

  return (
    <li>
      <Typography variant='body1' color='black' fontWeight={700} >
        {item?.title}
        {
          typeof item.description === 'string' ?
            <Typography variant='body1' color='black' fontWeight={400} component={'span'} sx={{ whiteSpace: 'pre-line'}}>
              {item?.description}
            </Typography> :
            item.description.map((part, index) => (
              typeof part === 'string' ?
                <Typography key={`part-${index}`} variant='body1' color='black' fontWeight={400} component={'span'} >
                  {part}
                </Typography> :
                <Link
                  to={part.link}
                  key={`part-${index}`}
                >
                  {part.text}
                </Link>
            ))
        }

      </Typography>

      {item?.listItems &&
        <ol style={{paddingLeft: '32px'}}>
          {
            item.listItems.map((item, index) => (
              <TermListItem key={`${idx}-${index}`} item={item} idx={`${idx}-${index}`}/>
            ))
          }
        </ol>
      }

      {
        item?.footerTitle &&
        <Typography variant='body1' color='black' fontWeight={700} >
          {item?.footerTitle}
          <Typography variant='body1' color='black' fontWeight={400} component={'span'} sx={{ whiteSpace: 'pre-line'}} >
            {item?.footerDescription}
          </Typography>
        </Typography>
      }
    </li>
  )
}

export default Terms;
