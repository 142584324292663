import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createBrowserRouter, useRouteError } from 'react-router-dom';
import ReactGA from 'react-ga';

import SignInEmail from './views/signinEmail';
import VerifyEmail from './views/verifyEmail';

import CreateCourse from './views/createCourse';
import AssessmentComponent from './views/createCourse/AssessmentComponent';
import CreatorOnBoarding from './views/creatorOnBoarding';
import CreatorProfile from './views/userProfile';
import UserChat from './views/userChat';
import ChatWidget from './views/chatWidget'
import FanCreatorList from './views/fanCreatorList';
import CreatorDashboard from './views/creatorDashboard';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import Logo from './assets/images/logo.png';
import Navbar from './views/navbar';
import ForgotPassword from './views/forgotPassword';
import LogoutAuthUser from './views/logout';
import Settings from './views/settings';
import FAQ from './views/faq';
import Terms from './views/termsAndPolicies';
import TrainSection from './views/trainSection';
import LevelQuestions from './views/trainSection/levelQuestions';
import SignInLanding from './views/signinLanding';
import MigrationScript from './views/migrationScripts';
import CreateProfile from './views/createUpdateProfile/createProfile';
import UpdateProfile from './views/createUpdateProfile/updateProfile';
import SignUp from './views/signup';
import EditCreatorProfile from './views/userProfile/editCreatorProfile';
import { Navigate } from 'react-router-dom';
import Purchases from './views/Purchases';
import StripeCompleteCheckout from './views/StripeCompleteCheckout';
import CoursesList from './views/coursesList';
import CourseDetails from './views/courseDetails';
import TakeCourse from './views/userChat/takeCourse';
import CourseReview from './views/courseReview';
import AssignmentReview from './views/courseReview/assignmentReview';
import CourseIntro from './views/createCourse/CourseIntro';
import CourseBanner from './views/createCourse/CourseBanner';
import CoursePreview from './views/createCourse/CoursePreview';
import ChatWidgetNavbar from './views/navbar/ChatWidgetNavbar';
import TwitterCallback from './views/creatorOnBoarding/TwitterCallback';
import Journaling from './views/journaling';
import SearchEngine from './views/searchEngine';
import Revenue from './views/Revenue';


const TRACKING_ID = process.env.REACT_APP_ANALYTICS_TRACKING_ID; // OUR_TRACKING_ID
const allow_widget_route = process.env.REACT_APP_ALLOW_CHAT_WIDGET_ROUTE === 'true';

ReactGA.initialize(TRACKING_ID);

const ConditionalRedirect = ({ children }) => {
  if (window.location.hostname === 'sambatv.mimio.ai') {
    return <Navigate to="/aipersona/samba.tv" replace />;
  }

  return children;
};

const isSambatv = window.location.hostname === 'sambatv.mimio.ai';
// Conditionally add the corporate accounts route
const additionalRoutes = isSambatv ? [{
  path: '/aipersona/:enterpriseName',
  errorElement: <ErrorBoundary />,
  element: (
    <PrivateRoute>
        <FanCreatorList />
    </PrivateRoute>
  ),
}] : [];


const routes = createBrowserRouter([
  {
    element: <Navbar />,
    children: [
      {
        path: '/',
        errorElement: <ErrorBoundary />,
        element: (
          <PublicRoute>
            <SignInLanding />
          </PublicRoute>
        ),
      },
      {
        path: '/signin',
        errorElement: <ErrorBoundary />,
        element: (
          <PublicRoute>
            <SignInLanding />
          </PublicRoute>
        ),
      },
      {
        path: '/signin-email',
        errorElement: <ErrorBoundary />,
        element: (
          <PublicRoute>
            <SignInEmail />
          </PublicRoute>
        ),
      },
      {
        path: '/verify-email',
        errorElement: <ErrorBoundary />,
        element: <VerifyEmail />,
      },
      {
        path: '/forgot-password',
        errorElement: <ErrorBoundary />,
        element: (
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        ),
      },
      {
        path: '/signup',
        errorElement: <ErrorBoundary />,
        element: (
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        ),
      },
      {
        path: '/creator-onboarding',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CreatorOnBoarding />
          </PrivateRoute>
        ),
      },
      {
        path: '/create-profile',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CreateProfile />
          </PrivateRoute>
        ),
      },
      {
        path: '/twitter/callback',
        errorElement: <ErrorBoundary />,
        element: <PublicRoute>
          <TwitterCallback />
        </PublicRoute>,
      },
      {
        path: '/update-profile',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <UpdateProfile />
          </PrivateRoute>
        ),
      },
      {
        path: '/logout-user',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <LogoutAuthUser />
          </PrivateRoute>
        ),
      },
      {
        path: '/migration-script',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <MigrationScript />
          </PrivateRoute>
        ),
      },
      {
        path: '/update',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CreatorOnBoarding />
          </PrivateRoute>
        ),
      },
      {
        path: '/createCourse',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CreateCourse />
          </PrivateRoute>
        ),
      },
      {
        path: '/CreateCourseIntro/:courseId',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CourseIntro />
          </PrivateRoute>
        ),
      },
      {
        path: '/CreateAssessments/:courseId',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <AssessmentComponent />
          </PrivateRoute>
        ),
      },
      {
        path: '/CreateCourseBanner/:courseId',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CourseBanner />
          </PrivateRoute>
        ),
      },
      {
        path: '/CoursePreview/:courseId',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CoursePreview />
          </PrivateRoute>
        ),
      },
        {
          path: '/:userName/courses=:courseName',
          errorElement: <ErrorBoundary />,
          element: (
            <PrivateRoute>
              <CourseDetails />
            </PrivateRoute>
          ),
        },
        {
        path: '/train',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <TrainSection />
          </PrivateRoute>
        ),
      },
      {
        path: '/:userName/update',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute authUserAccess={true}>
            <EditCreatorProfile />
          </PrivateRoute>
        ),
      },
      {
        path: '/:userName/purchases',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <Purchases />
          </PrivateRoute>
        ),
      },
      {
        path: '/complete_checkout',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <StripeCompleteCheckout />
          </PrivateRoute>
        ),
      },
      {
        path: '/dashboard',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CreatorDashboard />
          </PrivateRoute>
        ),
      },
      {
        path: '/creators',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <ConditionalRedirect>
              <FanCreatorList />
            </ConditionalRedirect>
          </PrivateRoute>
        ),
      },
      {
        path: '/courses',
        errorElement: <ErrorBoundary />,
        element: (
          <ConditionalRedirect>
            <CoursesList />
          </ConditionalRedirect>
        ),
      },
      {
        path: '/courses/:courseId',
        errorElement: <ErrorBoundary />,
        element: (
          <ConditionalRedirect>
            <CourseDetails />
          </ConditionalRedirect>
        ),
      },
      {
        path: '/learn/:courseId',
        errorElement: <ErrorBoundary />,
        element: (
          <ConditionalRedirect>
            <TakeCourse />
          </ConditionalRedirect>
        ),
      },
      {
        path: '/results/:courseId',
        errorElement: <ErrorBoundary />,
        element: (
          <ConditionalRedirect>
            <CourseReview />
          </ConditionalRedirect>
        ),
      },
      {
        path: '/results/:courseId/:studentId',
        errorElement: <ErrorBoundary />,
        element: (
          <ConditionalRedirect>
            <AssignmentReview />
          </ConditionalRedirect>
        ),
        
      },
      // {
      //   path: '/chat',
      //   errorElement: <ErrorBoundary />,
      //   element: (
      //     <PrivateRoute>
      //       <UserChat />
      //     </PrivateRoute>
      //   ),
      // },
      {
        path: '/settings',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        ),
      },

      {
        path: '/train/:levelId',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <LevelQuestions />
          </PrivateRoute>
        ),
      },
      {
        path: '/:userName/journaling',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <Journaling />
          </PrivateRoute>
        ),
        
      },
      {
        path: '/:userName/conversation/:topic',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <UserChat />
          </PrivateRoute>
        ),
      },
      {
        path: '/:userName',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <CreatorProfile />
          </PrivateRoute>
        ),
      },
      {
        path: '/:userName/chat',
        errorElement: <ErrorBoundary />,
        element: <UserChat />,
      },
      {
        path: '/faq',
        errorElement: <ErrorBoundary />,
        element: <FAQ />,
      },
      {
        path: '/terms',
        errorElement: <ErrorBoundary />,
        element: <Terms />,
      },
      {
        path: '/:userName/revenue',
        errorElement: <ErrorBoundary />,
        element: (
          <PrivateRoute>
            <Revenue />
          </PrivateRoute>
        ),
      },
      ...additionalRoutes,
    ],
  },
  allow_widget_route && {
    element: <ChatWidgetNavbar />,
    children: [
      {
        path: '/:userName/widget',
          errorElement: <ErrorBoundary />,
          element: <ChatWidget />
        },
    ]
  },
  {
      path: '/ask',
      errorElement: <ErrorBoundary />,
      element: (
        <SearchEngine/>
      ),
    },
]);

export default routes;

function ErrorBoundary() {
  let error: any = useRouteError();
  console.error(error);
  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '1.5rem',
      }}
    >
      <Box>
        <img src={Logo} alt='logo' width='100%' className='logo-img' />
        <Typography
          component='h5'
          variant='h1'
          color='gray'
          fontSize='4rem'
          fontWeight={500}
        >
          {error?.status}
        </Typography>
        <Typography
          component='h5'
          variant='h3'
          fontSize='1.5rem'
          color='gray'
          fontWeight={500}
          mt={2}
        >
          {error?.statusText}
        </Typography>
      </Box>
    </Box>
  );
}
