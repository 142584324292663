import * as React from 'react';

import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useQuery} from '@tanstack/react-query';
import {useAuth} from '../../hooks/useAuth';
import {fetchCreatorLevelInfo, fetchLevelQuestionsData, updateLevelQuestionsData,} from '../../utils/api';
import LevelQuestionItem from './levelQuestionItem';
import {sliceIntoChunks} from '../../utils/helper';
import {TRAIN_SECTION_LEVELS, TRAIN_SECTION_LEVELS_DATA, TRAIN_SECTION_STATUS, USER_TYPE,} from '../../config/const';
import LevelTwoQuestionItem from './levelTwoQuestionItem';
import {useSimpleDialog} from '../../hooks/useSimpleDialog';
import CelebrationIcon from '../../svg/CelebrationIcon';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const TOTAL_QUESTION_ITEMS = 10;
const PAGE_LIMIT = 5;

function LevelQuestions() {
  const { levelId: levelIdParam } = useParams();
  const levelId = levelIdParam?.toLowerCase();
  const { authUser } = useAuth();
  const { setData, setIsOpen } = useSimpleDialog();
  const navigate = useNavigate();

  const [routeValid, setRouteValid]: any = React.useState(false);
  const [levelLoading, setLevelLoading]: any = React.useState(false);
  const [levelQueAnsData, setLevelQueAnsData]: any = React.useState([]);
  const [formErrors, setFormErrors]: any = React.useState([]);
  const [levelStateData, setLevelStateData]: any = React.useState({});
  const [currentIndex, setCurrentIndex]: any = React.useState(0); //current index == current page
  const [formSubmitting, setFormSubmitting]: any = React.useState(false); //current index == current page

  const updateTextTimer = React.useRef<any>(0);

  const { data: levelsData, isLoading: trainLevelDataLoading } = useQuery({
    queryKey: ['creator-level-info', authUser?.documentId],
    queryFn: fetchCreatorLevelInfo,
    refetchOnMount: false,
  });
  const eventTracker = useAnalyticsEventTracker();
  const { data, isLoading } = useQuery({
    queryKey: ['creator-level-data', authUser?.documentId, levelId],
    queryFn: fetchLevelQuestionsData,
    enabled: routeValid,
  });
  eventTracker('fetch-level-questions');

  React.useEffect(() => {
    setLevelLoading(true);
    checkLevelId();
  }, [trainLevelDataLoading, levelId, levelsData, authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!trainLevelDataLoading && routeValid && data) {
      handleLevelQuestionData(data);
    }
  }, [trainLevelDataLoading, routeValid, data, levelId]); //eslint-disable-line react-hooks/exhaustive-deps

  // check current level Id and match with train status only allow user when level is available to attempt
  const checkLevelId = () => {
    if (trainLevelDataLoading) {
      return;
    }
    if (
      authUser &&
      (authUser?.userType !== USER_TYPE?.CREATOR || !authUser?.index)
    ) {
      toast.error('You are not authorized to access this location');
      navigate(`/creators`);
      return;
    }
    const levelIndex = Object.keys(TRAIN_SECTION_LEVELS)?.findIndex(
      (levelKey) => levelId === TRAIN_SECTION_LEVELS?.[levelKey]?.value
    );
    if (levelIndex === -1) {
      toast.error('Invalid URL. Please check the URL and try again');
      navigate(`/train`);
      return;
    }
    if (levelIndex >= 1 && levelsData?.[`level_${levelIndex}_progress`] < 100) {
      toast.error('You are not authorized to access this location');
      navigate(`/train`);
      return;
    }
    if (
      levelsData?.[`${levelId}_progress`] === undefined ||
      levelsData?.[`${levelId}_status`] === undefined
    ) {
      toast.error('You are not authorized to access this location');
      navigate(`/train`);
      return;
    }
    if (
      levelsData?.[`${levelId}_status`] &&
      levelsData?.[`${levelId}_status`] === TRAIN_SECTION_STATUS?.GENERATING
    ) {
      toast.error('The level data is being generated. Please try again later');
      navigate(`/train`);
      return;
    }
    setRouteValid(true);
  };

  // modify questions array data and divide into the define page, page limit to define number of questions displayed per page
  const handleLevelQuestionData = async (questionData: any) => {
    if (questionData && questionData?.questions?.length >= 1) {
      if (questionData?.progress === 100) {
        toast.error('Level is already completed');
        navigate(`/train`);
        return;
      }

      const slicedData = sliceIntoChunks(questionData?.questions, PAGE_LIMIT);
      setLevelQueAnsData(slicedData);
      validateCurrentFormIndex(slicedData, currentIndex);
      const levelData = { ...questionData };
      if (levelData?.questions?.length >= 1) {
        delete levelData?.questions;
      }
      setLevelStateData(levelData);
      setLevelLoading(false);
    }
  };

  // save level 1 and 3 user selected option data, validate all options are checked and save level progress
  // function call every time when user selects the answer
  const saveUserQuestionAnswerData = (question, answer) => {
    levelQueAnsData?.[currentIndex]?.map((questionItem, index) => {
      if (questionItem?.question === question) {
        questionItem.userAnswers = answer;
      }
      return {
        userAnswers: questionItem?.userAnswers || '',
        question: questionItem.question,
        options: questionItem.options,
      };
    });
    setLevelQueAnsData([...levelQueAnsData]);
    validateCurrentFormIndex(levelQueAnsData, currentIndex);
    handleLevelUserProgress();
  };

  // save level 2 user selected option/ custom input data, validate all options/input are checked/filled and save level progress
  // function call every time when user selects the answer
  const saveLevelTwoUserQuestionAnswerData = (
    truth,
    answer,
    answerType,
    isCustomAnswer: boolean = false
  ) => {
    levelQueAnsData?.[currentIndex]?.map((questionItem) => {
      if (questionItem?.truth === truth) {
        questionItem.userAnswers = answer;
        questionItem.userAnswerType = answerType;
      }
      return {
        userAnswers: questionItem?.userAnswers || '',
        userAnswerType: questionItem.userAnswerType || '',
        truth: questionItem.truth,
        lies: questionItem.lies,
      };
    });
    setLevelQueAnsData([...levelQueAnsData]);
    validateCurrentFormIndex(levelQueAnsData, currentIndex);
    // if user is giving free text answer, delay the save progress call to overcome multiple db call
    if (isCustomAnswer) {
      if (updateTextTimer?.current) {
        clearTimeout(updateTextTimer.current);
        updateTextTimer.current = 0;
      }
      updateTextTimer.current = setTimeout(() => {
        handleLevelUserProgress();
        updateTextTimer.current = 0;
      }, 1000);
    } else {
      handleLevelUserProgress();
    }
  };

  //save current level progress data in %
  const getLevelProgressData = () => {
    try {
      const progress = calculateCurrentProgress(levelQueAnsData);
      // if all questions options/input are given and valid and progress is 100 then set the current progress to 99 and only make it 100 when user submit using finish button
      return progress === 100 ? 99 : progress;
    } catch (error) {
      console.log('saveLevelProgressData-error', error);
    }
  };

  //save user answers data with progress to firebase
  const handleLevelUserProgress = async (formSubmit = false) => {
    try {
      let userQueAnsData = [];
      let progress = 0;
      let status = TRAIN_SECTION_STATUS?.IN_PROGRESS;
      // if function called from finish button set progress to 100 and make status completed else get current progress
      if (formSubmit) {
        progress = 100;
        status = TRAIN_SECTION_STATUS?.FINISH;
      } else {
        progress = getLevelProgressData();
      }
      // merge all the questions array
      for (let i = 0; i < levelQueAnsData?.length; i++) {
        userQueAnsData = [...userQueAnsData, ...levelQueAnsData?.[i]];
      }
      if (userQueAnsData?.length >= 1) {
        const updatedData = {
          user_id: levelStateData?.creatorId,
          doc_id: levelStateData?.doc_id,
          data: {
            creatorId: levelStateData?.creatorId,
            levelId: levelStateData?.levelId,
            progress: progress,
            status: status,
            questions: userQueAnsData,
          },
        };
        try {
          await updateLevelQuestionsData(updatedData);
          eventTracker('update-level-question');
        } catch (e) {
            eventTracker('update-level-question failed');
        }
      }
    } catch (error) {
      toast.error('Your response was not saved. Please try again later');
      console.log('error', error);
    }
  };

  // validate currently displaying questions and enable disable next/finish button based on that
  const validateCurrentFormIndex = (userData, currentPage) => {
    let errors: any = {};
    if (levelId === TRAIN_SECTION_LEVELS?.LEVEL2?.value) {
      userData?.[currentPage]?.forEach((questionItem) => {
        if (!questionItem.userAnswers) {
          errors = {
            ...errors,
            [questionItem?.truth]: {
              question: questionItem?.truth,
              errorMessage: 'The question is required',
            },
          };
        }
      });
    } else {
      userData?.[currentPage]?.forEach((questionItem) => {
        if (!questionItem.userAnswers) {
          errors = {
            ...errors,
            [questionItem?.question]: {
              question: questionItem?.question,
              errorMessage: 'The question is required',
            },
          };
        }
      });
    }

    setFormErrors(errors);
  };

  // check the form is valid by checking errors
  const isFormValid = React.useCallback(() => {
    return Object.keys(formErrors).length >= 1;
  }, [formErrors]);

  // fetch top section title and subtitle based on current level
  const getCurrentLevelHeader = React.useCallback(() => {
    const levelData = TRAIN_SECTION_LEVELS_DATA?.find(
      (item) => item?.levelId === levelId
    );
    return levelData?.sectionTopHeader ? (
      <Box
        display='flex'
        justifyContent='center'
        p={4}
        style={{
          backgroundColor: '#DFDFEF',
        }}
      >
        <Typography
          component='h6'
          variant='h3'
          fontSize='1.4rem'
          fontWeight={500}
        >
          {levelData?.sectionTopHeader}
        </Typography>
      </Box>
    ) : (
      <></>
    );
  }, [levelId]);

  // update the color of the top steps in the form as per current page
  const getCurrentLevelFormSteps = React.useCallback(() => {
    return (
      <Box display='flex' justifyContent='center' py={2}>
        {levelQueAnsData?.map((data, index) => (
          <Box
            key={index}
            borderRadius={10}
            alignSelf='center'
            justifySelf='center'
            height={8}
            width={40}
            display='flex'
            justifyContent='center'
            style={{
              backgroundColor: index <= currentIndex ? '#ED7841' : '#808080',
            }}
          ></Box>
        ))}
      </Box>
    );
  }, [levelQueAnsData, currentIndex]);

  // check is the current page is last page
  const isLastPage = React.useCallback(() => {
    return currentIndex + 1 === levelQueAnsData?.length;
  }, [currentIndex, levelQueAnsData]);

  // handle from submit
  const handleFormSubmit = () => {
    if (isFormValid) {
      if (isLastPage()) {
        handleSubmitFormData();
      } else {
        const next = currentIndex + 1;
        setCurrentIndex(next);
        validateCurrentFormIndex(levelQueAnsData, next);
      }
    }
  };

  // on form submit store the question data and progress and then show a successfully submitted message popup based on which level was completed
  const handleSubmitFormData = async () => {
    setFormSubmitting(true);
    try {
      await handleLevelUserProgress(true);
      let content = 'Level completed successfully';
      if (levelStateData?.levelId === TRAIN_SECTION_LEVELS?.LEVEL1?.value) {
        content = `Personality profiling completed successfully!`;
        //content = `${TRAIN_SECTION_LEVELS?.LEVEL1?.label} completed successfully! Keep going with ${TRAIN_SECTION_LEVELS?.LEVEL2?.label}`;
      } else if (
        levelStateData?.levelId === TRAIN_SECTION_LEVELS?.LEVEL2?.value
      ) {
        content = `${TRAIN_SECTION_LEVELS?.LEVEL2?.label} completed successfully! Keep going with ${TRAIN_SECTION_LEVELS?.LEVEL3?.label}`;
      } else if (
        levelStateData?.levelId === TRAIN_SECTION_LEVELS?.LEVEL3?.value
      ) {
        content = `${TRAIN_SECTION_LEVELS?.LEVEL3?.label} completed successfully!`;
      }
      setTimeout(() => {
        setData({
          title: '',
          levelId: levelStateData?.levelId,
          content: (
            <Box
              display='flex'
              width='100%'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              rowGap={2}
            >
              {content}
              <br />
              <CelebrationIcon height={80} />
            </Box>
          ),
        });
        setIsOpen(true);
        setFormSubmitting(false);
        navigate(`/train`);
      }, 1000);
    } catch (error) {
      setFormSubmitting(false);
      console.log('handleFormSubmit-error', error);
    }
  };

  // function used to calculate the current level progress based on selected options / given answers by the user
  const calculateCurrentProgress = (questionData) => {
    let answeredLength = 0;
    for (let i = 0; i < questionData?.length; i++) {
      answeredLength += questionData[i]?.filter(
        (questionItem) => questionItem?.userAnswers
      ).length;
    }
    return Math.round((answeredLength / TOTAL_QUESTION_ITEMS) * 100);
  };

  return (
    <>
      {trainLevelDataLoading || isLoading || levelLoading ? (
        <Box
          mt={2}
          sx={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <>
          {getCurrentLevelFormSteps()}
          {getCurrentLevelHeader()}
          <Box
            display='flex'
            flex={1}
            alignItems='start'
            flexDirection='column'
            // px={6}
            pt={1}
            pb={4}
            // rowGap={2}
            component='form'
            noValidate
            autoComplete='off'
          >
            {levelQueAnsData?.[currentIndex]?.length >= 1 && (
              <>
                {levelId === TRAIN_SECTION_LEVELS.LEVEL2?.value
                  ? levelQueAnsData?.[currentIndex]?.map(
                      (
                        levelQuestionItem: Record<string, string>,
                        index: string
                      ) => (
                        <LevelTwoQuestionItem
                          levelQuestionItem={levelQuestionItem}
                          key={index}
                          keyIndex={index}
                          formErrors={formErrors}
                          formSubmitting={formSubmitting}
                          isLastItem={
                            levelQueAnsData?.[currentIndex]?.length ===
                            index + 1
                          }
                          saveUserQuestionAnswerData={
                            saveLevelTwoUserQuestionAnswerData
                          }
                        />
                      )
                    )
                  : levelQueAnsData?.[currentIndex]?.map(
                      (
                        levelQuestionItem: Record<string, string>,
                        index: string
                      ) => (
                        <LevelQuestionItem
                          levelQuestionItem={levelQuestionItem}
                          key={index}
                          keyIndex={index}
                          formErrors={formErrors}
                          formSubmitting={formSubmitting}
                          isLastItem={
                            levelQueAnsData?.[currentIndex]?.length ===
                            index + 1
                          }
                          saveUserQuestionAnswerData={
                            saveUserQuestionAnswerData
                          }
                        />
                      )
                    )}
                <Grid container justifyContent='center'>
                  <Button
                    id={`button-${isLastPage() ? 'finish' : 'next'}`}
                    variant='contained'
                    disabled={isFormValid() || formSubmitting}
                    onClick={() => handleFormSubmit()}
                    startIcon={
                      formSubmitting && (
                        <CircularProgress size={16} color='inherit' />
                      )
                    }
                    sx={{
                      px: 4,
                      backgroundColor: '#51619E',
                      borderRadius: 3,
                      color: '#FFF',
                      border: '2px solid #DDDDDD',
                      '&:hover': {
                        backgroundColor: !(isFormValid() || formSubmitting)
                          ? '#414e7e'
                          : undefined,
                      },
                    }}
                  >
                    {isLastPage() ? 'Finish' : 'Next'}
                  </Button>
                </Grid>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default LevelQuestions;
