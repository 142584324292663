import { Link, Navigate } from "react-router-dom";
import { useAuth } from '../../hooks/useAuth';
import { Box, Typography } from '@mui/material';
import PurchaseHistory from './PurchaseHistory';
import SubscriptionData from './SubscriptionData';

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';
const enableCourses = process.env.REACT_APP_SHOW_MY_COURSE === 'true';

const Purchases: React.FC = () => {
  const { authUser, authLoading } = useAuth();

  if (!enableMonetization && !(enableCourses || authUser?.beta_tester || authLoading)) {
    // redirect to creators page
    return <Navigate to="/creators" />
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <SubscriptionData />
      <PurchaseHistory />

      <Typography color='black'
        sx={{
          mt: 4,
          mb: 4,
          '& a': {
            textDecoration: 'underline'
          }
        }}
      >
        {'Please email '}
        <Link to='mailto:support@soopra.ai'>support@soopra.ai</Link>
        {' for subscription questions or cancellations'}
      </Typography>
    </Box>
  )
}

export default Purchases;
