import { Box, Button, Modal, Backdrop, Typography, CircularProgress } from '@mui/material'
import { useContext, useState } from 'react'
import {JournalSaveContext} from '../../context/JournalSaveContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { handleSaveJournalChat } from '../../utils/api';
import { toast } from 'react-toastify';

const Journaling = () => {
  const [journalText, setJournalText] = useState('');
  const [savedChanges, setSavedChanges] = useState('');
  const [isSavingEntry, setSavingEntry] = useState(false);
  const {setSavedStatus, showSaveDialog, setShowSaveDialog} = useContext(JournalSaveContext)
  const placeholderText = 'Write or paste your journal entry here. This information will be used to train your AI Persona’s knowledge and personality.'
  const { authUser } = useAuth();
  const navigate = useNavigate();

  const handleJournalEntrySave = async (e) => {
    e.preventDefault();
    setSavingEntry(true);

    // Save function
    try {
      await postJournalEntry(journalText);
    }
    catch(error) {
      console.log(error)
    }
  }

  const postJournalEntry = async (message) => {
    try {
      let resp = await handleSaveJournalChat(authUser?.uid, message);
      if (resp) {
        setSavedChanges(journalText);
        setSavedStatus(true)
        toast.success('Saved journal entry successfully');
        setSavingEntry(false)
        navigate(-1)
      }
      else {
        toast.error('Unable to save journal entry');
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleJournalEntry = (e) => {
    setSavedStatus(false);
    setJournalText(e.target.value)
  }

  // Use for adding alertbox when route changes on Browser. (when back button next to url box of browser is clicked)

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (savedChanges !== journalText) {
  //       event.preventDefault();
  //       event.returnValue="";
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [savedChanges, journalText]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '330px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
    maxHeight: '90vh',
  };

  return (
    <Box className="journal-entry-container" display={'flex'} flexDirection={'column'} gap={2} p={2} width={'100%'} height={'100%'}>
        <Box className="journal-entry-header"><strong>New Journal Entry</strong></Box>
        <Box className="journal-entry-subheader">Train your AI Persona’s knowledge and personality with regular journal entries.</Box>
        <Box className="journal-entry-textbox" height={'60%'}>
            <textarea value={journalText} onChange={handleJournalEntry} style={{
              width: '100%',
              minHeight: '100%',
              fontFamily: 'Arial, sans-serif',
              padding: '10px',
              borderRadius: '10px',
              resize: 'none'
            }}
            placeholder={placeholderText}
            />
        </Box>
        <Box className="journal-entry-save-btn" width={'100%'} display={'flex'} justifyContent={'right'}>
          <Button variant='contained'
            sx={{
              height: '80% !important',
              borderRadius: '20px !important',
              position: 'relative'
            }}
            disabled = {savedChanges === journalText || isSavingEntry}
            onClick={handleJournalEntrySave}
          >
            {'Save'}
            {isSavingEntry && (
              <CircularProgress
                size={18}
                sx={{
                  position: 'absolute',
                }}
              />)}
          </Button>
        </Box>
        <Modal
          open={showSaveDialog}
          slots={{ backdrop: Backdrop }}
          slotProps={{
              backdrop: {
              timeout: 500,
              },
          }}
        >
          <Box sx={style} display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={'20px'}>
            <Box>
              <strong>Discard Journal Entry?</strong>
              <Typography color={'#000'} fontSize={'13px'}>This entry will be lost and your AI Persona will not be updated.</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={'10px'}>
              <Button
                sx={{
                  bgcolor: '#EF5F5F',
                  color: 'white',
                  borderRadius: '30px',
                  height: '40px !important',
                  '&:hover': {
                    bgcolor: "#cd4848 !important"
                  }
                }}
                onClick={() => {
                  setShowSaveDialog(false)
                  setSavedStatus(true)
                  navigate(-1)
                }}
              >
                Discard
              </Button>
              <Button
                variant='outlined'
                sx={{
                  borderRadius: '30px',
                  height: '40px !important'
                }}
                onClick={() => setShowSaveDialog(false)}
              >
                Continue Editing
              </Button>
            </Box>
          </Box>
        </Modal>
    </Box>
  )
}

export default Journaling;
