import React, {useState, createContext, memo} from 'react'

interface JournalSaveCtxType {
  savedStatus: any;
  setSavedStatus: (journalSavedStatus: boolean) => void;
  showSaveDialog: any, 
  setShowSaveDialog: (dialogOpen: boolean) => void;
}

export const JournalSaveContext = createContext<JournalSaveCtxType>({
  savedStatus: null,
  setSavedStatus: () => {
    return {} as any;
  },
  showSaveDialog: null, 
  setShowSaveDialog: () => {
    return {} as any;
  },
});

export const JournalSaveProvider = ({
  children
}: {
  children: React.ReactNode;
})=> {
    const [savedStatus, setSavedStatus] = useState<boolean>(true)
    const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false)
    return (
      <JournalSaveContext.Provider value={{ savedStatus, setSavedStatus, showSaveDialog, setShowSaveDialog }}>
        {children}
      </JournalSaveContext.Provider>
    );
  };
  
  export default memo(JournalSaveProvider);