import { Timestamp } from 'firebase/firestore'
export type Tier = keyof typeof TIERS
export type Permission = (typeof TIERS)[Tier][number]

const TIERS = {
  4000: [ // admin
    "view:chathistory",
    "update:chathistory",
    "view:analytics",
    "train:youtube",
    "train:twitter",
    "create:course",
    "publish:course",
    "update:course",
    "delete:course",
    "create:voice",
    "update:voice",
    "use:voice",
    "delete:voice",
    "export:emails",
    "limit:chatLimitUltimate",
  ],
  3000: [ // ultimate
    "view:chathistory",
    "update:chathistory",
    "view:analytics",
    "train:youtube",
    "train:twitter",
    "create:course",
    "publish:ownCourse",
    "update:ownCourse",
    "delete:ownCourse",
    "create:voice",
    "update:voice",
    "use:voice",
    "delete:voice",
    "export:emails",
    "limit:chatLimitUltimate",
  ],
  2000: [ // premium
    "view:chathistory",
    "update:chathistory",
    "view:analytics",
    "train:youtube",
    "create:course",
    "publish:ownCourse",
    "update:ownCourse",
    "delete:ownCourse",
    "limit:chatLimitPremium",
  ],
  1000: [ // standard
    "view:chathistory",
    "update:chathistory",
    "view:analytics",
    "limit:chatLimitStandard",
  ],
  100: [ // beginner
  ]
}

export function hasPermission(
  user: { id: string; subscription: {pricing_plan: {tier: Tier, expiration_date: any}}},
  permission: Permission,
) {
  if (!(process.env.REACT_APP_ENABLE_PAYWALLS === 'true')) {
    return true
  }
  if (!user?.subscription?.pricing_plan?.tier) {
    return false;
  }
  if ((TIERS[user.subscription.pricing_plan.tier] as readonly Permission[]).includes(permission)) {
    // check expiration date
    if (user.subscription.pricing_plan.expiration_date < Timestamp.fromDate(new Date())){
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

// Get string for minimum tier level required
export function minPermission(
  permission: Permission
) {
  for (let tier of [100, 1000, 2000, 3000]){
    if (TIERS[tier].includes(permission)){
      switch (tier){
        case 100:
          return "Beginner"
        case 1000:
          return "Standard"
        case 2000:
          return "Premium"
        case 3000:
          return "Ultimate"
        default:
          return "Ultimate"
      }
    }
  }
}
