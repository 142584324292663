import {
  TrainLevelData,
  ConversationData,
} from '../views/trainSection/train.types';

export const USER_TYPE = {
  CREATOR: 'creator',
  FOLLOWER: 'fan',
  BOT: 'bot',
};

export const SUPPORTED_IMAGE_MIME_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
];

export const USER_CHAT_HISTORY = -10; //last 10 messages

export const CONTACT_US_MAIL = 'support@soopra.ai';

export const TRAIN_SECTION_STATUS = {
  FINISH: 'Finish',
  IN_PROGRESS: 'In Progress',
  NOT_STARTED: 'Not Started',
  GENERATING: 'Generating',
};

export const TRAIN_SECTION_LEVELS = {
  LEVEL1: { label: 'Level 1', value: 'level_1' },
  LEVEL2: { label: 'Level 2', value: 'level_2' },
  LEVEL3: { label: 'Level 3', value: 'level_3' },
};

export const TRAIN_SECTION_LEVELS_DATA: TrainLevelData[] = [
  {
    levelId: TRAIN_SECTION_LEVELS?.LEVEL1?.value,
    level: '1',
    title: 'What is Your AI Personality Type?',
    subTitle: 'Accurately Measure Your Key Persona Traits',
    cardContent:
      'Quiz gauging key persona traits for self-awareness and personal growth',
  },
  // {
  //   levelId: TRAIN_SECTION_LEVELS?.LEVEL2?.value,
  //   level: '2',
  //   title: '2 Lies and a Truth',
  //   subTitle: 'Verify Your Persona’s Information',
  //   cardContent: 'Validate questions your fans might have to ensure accuracy',
  //   sectionTopHeader: 'Pick the answer that is correct',
  // },
  // {
  //   levelId: TRAIN_SECTION_LEVELS?.LEVEL3?.value,
  //   level: '3',
  //   title: 'Would You Rather',
  //   subTitle: 'Get To Know Your Preferences and Values',
  //   cardContent:
  //     'Quiz gauging likes and dislikes and reactions for deeper persona creation',
  //   sectionTopHeader: 'Choose what you would do in each situation',
  // },
];

export const CONVERSATIONAL_SECTION_DATA: ConversationData[] = [
  {
    topic: 'sports',
    title: 'Sports',
    subTitle: 'Get to know your favorite sports teams',
    cardContent: 'Chat with your AI Persona about sports',
  },
  {
    topic: 'movies',
    title: 'Movies',
    subTitle: 'Get to know your favorite movies',
    cardContent: 'Chat with your AI Persona about movies',
  },
  {
    topic: 'technology',
    title: 'Technology',
    subTitle: 'Get to know your favorite technologies',
    cardContent: 'Chat with your AI Persona about technology',
  },
];

export const SPECIAL_DOMAINS = /\.(edu|gov|com|net|org|ai|co|us|co.uk)$/i;

export const STRIPE_PRODUCT_LOOKUP_KEYS = {
  "4": "4_week_course",
  "2": "2_week_course",
  "free_course": "free_course",
  "subscription": "basic_soopra_subscription",
  "tenPackCredits": "basic_10_pack",
}

export const STRIPE_PRODUCT_DISPLAY_NAMES = {
  "4_week_course": "4 Module Course",
  "2_week_course": "2 Module Course",
  "basic_soopra_subscription": "Subscription",
  "basic_10_pack": "Additional Question Pack",
  "tier_free_a": "Free Subscription Tier",
  "tier_standard_a": "Standard Subscription Tier",
  "tier_premium_a": "Premium Subscription Tier",
  "tier_ultimate_a": "Ultimate Subscription Tier",
  "tier_free_b": "Free Subscription Tier",
  "tier_standard_b": "Standard Subscription Tier",
  "tier_premium_b": "Premium Subscription Tier",
  "tier_ultimate_b": "Ultimate Subscription Tier",
}

export const COURSE_PRICES = {"free_course": "Free!", 2: "$40.00", 4: "$60.00",}

export const PRICES_A = {
  'standard': 9,
  'premium': 69,
  'ultimate': 99
}

export const PRICES_B = {
  'standard': 29,
  'premium': 99,
  'ultimate': 299
}

export const TIER_LEVEL_CONVERSION = {
  'free': 100,
  'standard': 1000,
  'premium': 2000,
  'ultimate': 3000,
  'enterprise': 3100,
  'academic': 3200,
}

// export const LIMITED_RELEASE_USERS = [
//   "ronjon", "rj.vosburgh@gmail.com", "gzaltman", "bill", "Niemi", "LindaOle", "kswaiss",
//   "david_tan", "srinivasjaini", "Agarwal", "adiagarwal", "praveena", "Praveenad", "Speace", "srilekha_vutukuru", "gurpreet_singh",
//   "praveena.dhanalakota", "srinivas81", "david-MIMIO", "aditya.agarwal",
// ];
