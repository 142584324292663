import { Avatar, Box, Button, Tooltip, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react'
import './searchEngineStyles.css'
import SoopraLogo from '../../assets/images/logo.png';
import Lottie from "lottie-react";
import KopiLoading from '../../assets/animations/kopiLoading.json'
import SoopraSmile from '../../svg/SoopraSmile';
import { useAuth } from "../../hooks/useAuth";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchRandomCreator, fetchSearchResponseData, getSearchSummaryData } from '../../utils/api';
import ResponseCard from './responseCard';
import SignInSignUpModal from '../userChat/signSignUpModal';

const SearchEngine: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [queryResponse, setQueryResponse] = useState(null);
    const [handleSignInModal, setHandleSignInModal] = useState<boolean>(false);
    const [showResultPage, setShowResultPage] = useState(false)
    const [loadingAnimation, setLoadingAnimation] = React.useState(KopiLoading);
    const [responsesLoading, setResponsesLoading] = useState(false);
    const [summaryComputing, setSummaryComputing] = useState(false);
    const [summary, setSummary] = useState('');
    const tooltipTitle = "Take a chance and explore something new!"
    const [searchParams] = useSearchParams();
    const { authUser } = useAuth();

    const searchCache = useRef({});
    const summaryCache = useRef({});

    const handleSearchQuery = (e) => {
        e.preventDefault();
        setSearchQuery(e.target.value);
    }

    const computeSummary = async (responseList) => {
        setSummaryComputing(true);
        if (summaryCache.current[searchQuery]) {
                setSummary(summaryCache.current[searchQuery]);
                setSummaryComputing(false);
        }
        else {
            try {
                let resp = await getSearchSummaryData(responseList);
                summaryCache.current[searchQuery] = resp.response;
                setSummary(resp.response)
                
            } catch (error) {
                console.error('Error generating Summary: ', error)
            } finally {
                setSummaryComputing(false);
            }
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`?query=${encodeURIComponent(searchQuery)}`);
    }

    const handleParamsChange = async (query) => {
        setResponsesLoading(true);
        setQueryResponse(null);
        setSummary(null);
        setShowResultPage(true);

        if (searchCache.current[query]) {
            setQueryResponse(searchCache.current[query]);
            setResponsesLoading(false);
        }
        else {
            try {
                let resp = await fetchSearchResponseData(query);
                searchCache.current[query] = resp[0].response;
                setQueryResponse(resp[0].response)
            } catch (error) {
                console.error('Error getting search info: ', error)
            } finally {
                setResponsesLoading(false);
            }
        }
    }

    const navigate = useNavigate()

    const LoadingAnimation = ({text}) => {
        return(
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'} flexDirection={'column'} gap={'0px'}>
                <Lottie
                    animationData={loadingAnimation}
                    loop={true}
                    style={{
                        width: '120px',
                        height: '120px'
                    }}/>
                
                {text} ...
            </Box>
        );
    }
    useEffect(() => {
        document.getElementById('app-main').style.height = '100%';
        document.getElementById('app-main').style.marginTop = '0px';
      }, [])

    useEffect(() => {
        if (queryResponse) {
            const responseList = queryResponse.map(item => item.response).flat();
            computeSummary(responseList); // No need for `e` here
        }
    }, [queryResponse]);

    useEffect(() => {
        const urlQuery = searchParams.get('query');
        if (urlQuery) {
            setSearchQuery(urlQuery);
            handleParamsChange(urlQuery);
        }
      }, [searchParams]);


    const handleFeelingLucky = async () => {
        try {
            let resp = await fetchRandomCreator();
            window.open(`${window.location.origin}/${resp.response}`, '_blank')
        } catch (error) {
            console.error('Error getting search info: ', error)
        }
    }

  return (
    <>
    {!showResultPage && 
    <Box display={'flex'} position={'absolute'} className="sign-up-sign-in-button">
        {authUser ? 
            <Box>
                <a href={`${authUser.userName}`}>
                <Avatar
                src={authUser.profilePhotoURL}
                alt={authUser.fullName}
                sx={{
                width: 35,
                height: 35,
                border: '2px solid #fff',
                fontSize: '1.625rem'
                }}
            >{authUser.fullName ? authUser.fullName[0] : ''}</Avatar>
            </a>
            </Box>
            :
            <Box>
                <Button sx={{
                    border: '1.5px solid #00A3FF',
                    color: '#00A3FF',
                    width: '150px',
                    height:'40px !important',
                    "&:hover": {
                        background: '#00A3FF',
                        color: '#ffffff'
                    }
                }}
                onClick={() => setHandleSignInModal(true)}
                >Sign Up / Sign In</Button>
            <SignInSignUpModal handleSignInModal={handleSignInModal} setHandleSignInModal={setHandleSignInModal} />
        </Box>}
    </Box>}
    <Box display={'flex'} flexDirection={'column'} gap={'20px'} justifyContent={'center'} height={showResultPage ? 'max-content !important' : '100%'}>
        <Box marginBottom={showResultPage && '30px'} marginTop={showResultPage && '30px'} display={'flex'} flexDirection={showResultPage ? 'row' : 'column'} gap={'25px'} justifyContent={'center'} alignItems={'center'} className="search-query-header-container">
            <Box display={'flex'} justifyContent={showResultPage && window.innerWidth <=768 ? 'space-between' : 'center'} alignItems={'center'} className="search-query-header-image-container" width={showResultPage ? window.innerWidth <=768 ? '90%' : '10%' : window.innerWidth <=768 ? '90%' : '35%'}>
                <Box className="search-query-header-image-wrapper">
                    <a href='/ask' style={{display: 'flex'}}><img src={SoopraLogo} style={{width: '100%'}}/></a>
                </Box>
                {showResultPage &&
                    <Box className="sign-in-mobile-component">
                        {authUser ? 
                        <Box>
                            <a href={`${authUser.userName}`}>
                                <Avatar
                                src={authUser.profilePhotoURL}
                                alt={authUser.fullName}
                                sx={{
                                border: '2px solid #fff',
                                fontSize: '1.625rem'
                                }}
                            >{authUser.fullName ? authUser.fullName[0] : ''}</Avatar>
                            </a>
                        </Box>
                        :
                        <Box>
                            <Button sx={{
                                border: '1.5px solid #00A3FF',
                                color: '#00A3FF',
                                width: '150px',
                                height:'40px !important',
                                "&:hover": {
                                    background: '#00A3FF',
                                    color: '#ffffff'
                                }
                            }}
                            onClick={() => {setHandleSignInModal(true)}}
                            >Sign Up / Sign In</Button>
                            <SignInSignUpModal handleSignInModal={handleSignInModal} setHandleSignInModal={setHandleSignInModal} />
                        </Box>}
                    </Box>
                }
            </Box>
            <Box width={'100%'} display={'flex'} gap={'20px'} flexDirection={showResultPage ? 'row' :'column'} justifyContent={showResultPage ? 'flex-start':'center'} alignItems={showResultPage ? 'flex-start':'center'} className="search-query-box-container">
                <form onSubmit={searchQuery ? (e) => handleSearch(e) : () => {}} className='search-query-box' style={{width:  window.innerWidth <= 768 ? showResultPage ? '100%' : '90%' : showResultPage ? '100%' : '60%', height: '40px'}}>
                    <input value={searchQuery} onChange={(e) => handleSearchQuery(e)} className='search-query-input' placeholder='Ask Anything'/>
                    <button className='search-query-btn' type='submit'>
                        <SoopraSmile width={'29'} height={'23'}/>
                    </button>
                </form>
            {showResultPage ?
                <Box className="sign-up-sign-in-component">
                    {authUser ?
                        <Box>
                            <a href={`${authUser.userName}`}>
                                <Avatar
                                src={authUser.profilePhotoURL}
                                alt={authUser.fullName}
                                sx={{
                                border: '2px solid #fff',
                                fontSize: '1.625rem'
                                }}
                            >{authUser.fullName ? authUser.fullName[0] : ''}</Avatar>
                            </a>
                        </Box>
                        :
                        <Box>
                            <Button sx={{
                                border: '1.5px solid #00A3FF',
                                color: '#00A3FF',
                                width: '150px',
                                height:'40px !important',
                                "&:hover": {
                                    background: '#00A3FF',
                                    color: '#ffffff'
                                }
                            }}
                            onClick={() => {setHandleSignInModal(true)}}
                            >Sign Up / Sign In</Button>
                            <SignInSignUpModal handleSignInModal={handleSignInModal} setHandleSignInModal={setHandleSignInModal} />
                        </Box>}
                    </Box>
                :
                <Box display={'flex'} flexDirection={'row'} gap={'10px'}  className="search-home-btn-grp">
                    <Button sx={{
                        border: '1.5px solid #00A3FF',
                        color: '#00A3FF',
                        width: '150px',
                        height:'40px !important',
                        "&:hover": {
                            background: '#00A3FF',
                            color: '#ffffff'
                        }
                    }}
                    
                    onClick={() => navigate('/creators')}
                    >Explore Creators</Button>
                    <Tooltip title={tooltipTitle} arrow><Button sx={{
                        border: '1.5px solid #00A3FF',
                        color: '#00A3FF',
                        width: '150px',
                        height:'40px !important',
                        "&:hover": {
                            background: '#00A3FF',
                            color: '#ffffff'
                        }
                    }}
                    onClick={handleFeelingLucky}
                    >Take a Chance!</Button></Tooltip>
                </Box>
            }
        </Box>
        </Box>
        {showResultPage && 
        <Box display={'grid'} gridTemplateColumns={'0.65fr 0.35fr'} className="search-result-container">
            <Box display={'flex'} flexDirection={'column'} padding={'10px'} gap={'15px'} height={'85vh'} overflow={'scroll'} className="search-response-card-container">
                {responsesLoading ?
                <LoadingAnimation text={'Curating'}/>
                :
                queryResponse?.map((query, id) => {
                    return(
                    <ResponseCard profile_data={query.profile_data} response={query.response} id={id} response_time={query.time_taken} user_id = {query.user_id}/>
                    );
                })
                }
            </Box>
            <Box padding={'10px 10px 10px 35px'} borderLeft={'1px solid #eeeeee'} display={'flex'} flexDirection={'column'} gap={'10px'} className="search-summary-card-container">
                {(summaryComputing || summary )&& 
                    <Box padding={'20px'} bgcolor={'#eeeeee'} borderRadius={'10px'} minHeight={'35vh'} className={summaryComputing ? 'card-loading': ''}>
                    <Box padding={'5px'} borderBottom={'1px solid #31313125'}>
                        Expert Overview
                    </Box>
                    {summary &&
                        <Box padding={'5px'}>
                            <Typography color={'#313131'}>{summary}</Typography>
                        </Box>}
                </Box>}
                <Box display={'flex'} flexDirection={'row'} gap={'10px'} justifyContent={'space-between'}>
                    <Button sx={{
                        border: '1.5px solid #00A3FF',
                        color: '#00A3FF',
                        width: '-webkit-fill-available',
                        height:'40px !important',
                        "&:hover": {
                            background: '#00A3FF',
                            color: '#ffffff'
                        }
                    }}
                    onClick={() => navigate('/creators')}
                    >Explore Experts</Button>
                    <Button sx={{
                        border: '1.5px solid #00A3FF',
                        color: '#00A3FF',
                        width: '-webkit-fill-available',
                        height:'40px !important',
                        "&:hover": {
                            background: '#00A3FF',
                            color: '#ffffff'
                        }
                    }}
                    onClick={() => navigate('/courses')}
                    >Explore Courses</Button>
                </Box>
            </Box>
        </Box>}
    </Box>
    </>
  )
}

export default SearchEngine;
