import { Grid } from "@mui/material"
import SubscriptionAnalytics from "./SubscriptionAnalytics"
import EditCustomPriceCard from "./editCustomPriceCard";

interface RevenueProps {

}

const Revenue: React.FC<RevenueProps> = () => {
  const now = new Date();

  return (
    <Grid container spacing={2} p={{xs: 1.5, sm: 0}}>
      <Grid item xs={12} sm={9}>
        <EditCustomPriceCard />
      </Grid>

      <Grid item xs={12} sm={3}>
        <SubscriptionAnalytics month={now.getMonth()+1} year={now.getFullYear()}/>
      </Grid>
    </Grid>
  )
}

export default Revenue
